<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.BusinessRules",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <router-link
        :to="{
          path: '/ProjectDesign/BusinessRule/List',
        }"
        class="btn btn-action-list-page"
      >
        <i class="bi bi-list"></i>
        {{
          $t(
            "BusinessRule.AllData",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
      <router-link
        :to="{
          name: 'CustomObjectNewBusinessRule',
          params: { customObjectId: this.$route.params.customObjectId },
        }"
        class="btn btn-success btn-action-new-page"
      >
        <i class="bi bi-plus"></i>
        {{
          $t(
            "BusinessRule.NewBusinessRule",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
    </div>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onBusinessRuleActionButtonClick="onBusinessRuleActionButtonClick"
  />
</template>
<script>
export default {
  name: "CustomObjectBusinessRules",
  components: {},
  data() {
    return {
      gridSettings: {
        action: "CustomObjectBusinessRules",
        requestUrl: String.format("/Brs-BusinessRuleGetOfCustomObject"),
        requestUrlRouteParamNames: [{ key: "id", value: "customObjectId" }],
        routeRequiredParameters: [
          {
            key: "businessRuleId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        serialNoLink: `#/CustomObject/BusinessRuleActions/__CUSTOMOBJECTPUBLICID__&businessRuleId=`,

        allowSearchPanel: false,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/Brs-BusinessRuleDelete",
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [
          {
            name: "action",
            cssClass: "btn-primary",
            iconClass: "bi-lightning-fill",
            routeButton: false,
            emitMethodName: "onBusinessRuleActionButtonClick",
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditBusinessRule",
              params: {
                customObjectId: "",
                businessRuleId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "BusinessRule.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRule.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRule.BusinessProcess",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "businessProcess",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRule.BusinessRuleTriggerType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "businessRuleTriggerType",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRule.IsNotShowResultToClient",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isNotShowResultToClient",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onBusinessRuleActionButtonClick(rowData) {
      localStorage.setItem(
        "businessRuleTriggerTypeId",
        rowData.businessRuleTriggerTypeId
      );
      this.$router.push({
        name: "CustomObjectBusinessRuleActions",
        params: {
          customObjectId: this.$route.params.customObjectId,
          businessRuleId: rowData.publicId,
        },
      });
    },
  },
};
</script>
